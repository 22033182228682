/* tslint:disable */
/* eslint-disable */
/**
 * K1 OpenAPI
 * API fuer die Nutzung der K1 REST-Schnittstelle
 *
 * The version of the OpenAPI document: 1.134.0
 * Contact: diplan-backend@init.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { ErrorRest } from '../models';
// @ts-ignore
import { ExterneUrlsRest } from '../models';
// @ts-ignore
import { PlanInfoRest } from '../models';
// @ts-ignore
import { ValidationReportRest } from '../models';
// @ts-ignore
import { XplanDokumentRest } from '../models';
// @ts-ignore
import { XplanManagerStatusMessageRest } from '../models';
/**
 * XplanManagerResourceApi - axios parameter creator
 * @export
 */
export const XplanManagerResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * <b>Benötigte Rechte:</b> PLANWERKE_WRITE or EIGENE_PLANWERKE_WRITE or ADMIN
         * @param {string} planID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteXplanGml: async (planID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('deleteXplanGml', 'planID', planID)
            const localVarPath = `/verfahren/{planID}/xplanmanager`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Benötigte Rechte:</b> PLANWERKE_READ or EIGENE_PLANWERKE_READ or ADMIN
         * @param {string} planID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllXplanExterneUrls: async (planID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('getAllXplanExterneUrls', 'planID', planID)
            const localVarPath = `/verfahren/{planID}/xplanmanager/urls`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Benötigte Rechte:</b> ADMIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllXplanGml: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/verfahren/xplanmanager`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b> Publizieren eines existierenden XPlanGmlArchivs im XPlan-Manager.</br></br><b>Benötigte Rechte:</b> PLANWERKE_WRITE or EIGENE_PLANWERKE_WRITE or ADMIN
         * @summary Publizieren eines existierenden XPlanGmlArchivs im XPlan-Manager.
         * @param {string} planID planID des Verfahrens
         * @param {string} fileID fileID des XPlanGmlArchivs
         * @param {boolean} [skipSemantisch] 
         * @param {boolean} [skipFlaechenschluss] 
         * @param {boolean} [skipGeltungsbereich] 
         * @param {boolean} [skipLaufrichtung] 
         * @param {boolean} [overwrite] Ggf vorhandenes XplanGML im XplanManager ueberschreiben
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishXPlanGml: async (planID: string, fileID: string, skipSemantisch?: boolean, skipFlaechenschluss?: boolean, skipGeltungsbereich?: boolean, skipLaufrichtung?: boolean, overwrite?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('publishXPlanGml', 'planID', planID)
            // verify required parameter 'fileID' is not null or undefined
            assertParamExists('publishXPlanGml', 'fileID', fileID)
            const localVarPath = `/verfahren/{planID}/xplanmanager/{fileID}/publishXPlanGml`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)))
                .replace(`{${"fileID"}}`, encodeURIComponent(String(fileID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skipSemantisch !== undefined) {
                localVarQueryParameter['skipSemantisch'] = skipSemantisch;
            }

            if (skipFlaechenschluss !== undefined) {
                localVarQueryParameter['skipFlaechenschluss'] = skipFlaechenschluss;
            }

            if (skipGeltungsbereich !== undefined) {
                localVarQueryParameter['skipGeltungsbereich'] = skipGeltungsbereich;
            }

            if (skipLaufrichtung !== undefined) {
                localVarQueryParameter['skipLaufrichtung'] = skipLaufrichtung;
            }

            if (overwrite !== undefined) {
                localVarQueryParameter['overwrite'] = overwrite;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b> Hochladen des XPlanGmlArchivs und optionales publizieren im XPlan-Manager.</br></br><b>Benötigte Rechte:</b> PLANWERKE_WRITE or EIGENE_PLANWERKE_WRITE or ADMIN
         * @summary Hochladen des XPlanGmlArchivs und optionales publizieren im XPlan-Manager.
         * @param {File} file 
         * @param {string} planID planID des Verfahrens
         * @param {boolean} [overwrite] Ggf vorhandenes XplanGML im XplanManager ueberschreiben
         * @param {boolean} [publish] &lt;code&gt;true&lt;/code&gt; wenn die XplanGML im XplanManager veroeffentlicht werden soll, sonst &lt;code&gt;false&lt;/code&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadXPlanGml: async (file: File, planID: string, overwrite?: boolean, publish?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('uploadXPlanGml', 'file', file)
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('uploadXPlanGml', 'planID', planID)
            const localVarPath = `/verfahren/{planID}/xplanmanager/uploadXPlanGml`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (overwrite !== undefined) {
                localVarQueryParameter['overwrite'] = overwrite;
            }

            if (publish !== undefined) {
                localVarQueryParameter['publish'] = publish;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b> Validieren eines XPlanGML im XPlanManager.</br></br><b>Benötigte Rechte:</b> PLANWERKE_READ or EIGENE_PLANWERKE_READ or ADMIN
         * @summary Validieren eines XPlanGML im XPlanManager.
         * @param {string} planID planID des Verfahrens
         * @param {string} fileID fileID des XPlanGmlArchivs
         * @param {boolean} [skipSemantisch] Option zum Auslassen eine Validierung im XPlanValidator
         * @param {boolean} [skipGeometrisch] Option zum Auslassen eine Validierung im XPlanValidator
         * @param {boolean} [skipFlaechenschluss] Option zum Auslassen eine Validierung im XPlanValidator
         * @param {boolean} [skipGeltungsbereich] Option zum Auslassen eine Validierung im XPlanValidator
         * @param {boolean} [skipLaufrichtung] Option zum Auslassen eine Validierung im XPlanValidator
         * @param {string} [name] Optionale Angabe eines Namens fuer den zu generierenden Report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateXPlanGml: async (planID: string, fileID: string, skipSemantisch?: boolean, skipGeometrisch?: boolean, skipFlaechenschluss?: boolean, skipGeltungsbereich?: boolean, skipLaufrichtung?: boolean, name?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('validateXPlanGml', 'planID', planID)
            // verify required parameter 'fileID' is not null or undefined
            assertParamExists('validateXPlanGml', 'fileID', fileID)
            const localVarPath = `/verfahren/{planID}/xplanmanager/{fileID}/validateXplanGml`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)))
                .replace(`{${"fileID"}}`, encodeURIComponent(String(fileID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skipSemantisch !== undefined) {
                localVarQueryParameter['skipSemantisch'] = skipSemantisch;
            }

            if (skipGeometrisch !== undefined) {
                localVarQueryParameter['skipGeometrisch'] = skipGeometrisch;
            }

            if (skipFlaechenschluss !== undefined) {
                localVarQueryParameter['skipFlaechenschluss'] = skipFlaechenschluss;
            }

            if (skipGeltungsbereich !== undefined) {
                localVarQueryParameter['skipGeltungsbereich'] = skipGeltungsbereich;
            }

            if (skipLaufrichtung !== undefined) {
                localVarQueryParameter['skipLaufrichtung'] = skipLaufrichtung;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * XplanManagerResourceApi - functional programming interface
 * @export
 */
export const XplanManagerResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = XplanManagerResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * <b>Benötigte Rechte:</b> PLANWERKE_WRITE or EIGENE_PLANWERKE_WRITE or ADMIN
         * @param {string} planID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteXplanGml(planID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<XplanManagerStatusMessageRest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteXplanGml(planID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XplanManagerResourceApi.deleteXplanGml']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Benötigte Rechte:</b> PLANWERKE_READ or EIGENE_PLANWERKE_READ or ADMIN
         * @param {string} planID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllXplanExterneUrls(planID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExterneUrlsRest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllXplanExterneUrls(planID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XplanManagerResourceApi.getAllXplanExterneUrls']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Benötigte Rechte:</b> ADMIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllXplanGml(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PlanInfoRest>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllXplanGml(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XplanManagerResourceApi.getAllXplanGml']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b> Publizieren eines existierenden XPlanGmlArchivs im XPlan-Manager.</br></br><b>Benötigte Rechte:</b> PLANWERKE_WRITE or EIGENE_PLANWERKE_WRITE or ADMIN
         * @summary Publizieren eines existierenden XPlanGmlArchivs im XPlan-Manager.
         * @param {string} planID planID des Verfahrens
         * @param {string} fileID fileID des XPlanGmlArchivs
         * @param {boolean} [skipSemantisch] 
         * @param {boolean} [skipFlaechenschluss] 
         * @param {boolean} [skipGeltungsbereich] 
         * @param {boolean} [skipLaufrichtung] 
         * @param {boolean} [overwrite] Ggf vorhandenes XplanGML im XplanManager ueberschreiben
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publishXPlanGml(planID: string, fileID: string, skipSemantisch?: boolean, skipFlaechenschluss?: boolean, skipGeltungsbereich?: boolean, skipLaufrichtung?: boolean, overwrite?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<XplanDokumentRest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publishXPlanGml(planID, fileID, skipSemantisch, skipFlaechenschluss, skipGeltungsbereich, skipLaufrichtung, overwrite, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XplanManagerResourceApi.publishXPlanGml']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b> Hochladen des XPlanGmlArchivs und optionales publizieren im XPlan-Manager.</br></br><b>Benötigte Rechte:</b> PLANWERKE_WRITE or EIGENE_PLANWERKE_WRITE or ADMIN
         * @summary Hochladen des XPlanGmlArchivs und optionales publizieren im XPlan-Manager.
         * @param {File} file 
         * @param {string} planID planID des Verfahrens
         * @param {boolean} [overwrite] Ggf vorhandenes XplanGML im XplanManager ueberschreiben
         * @param {boolean} [publish] &lt;code&gt;true&lt;/code&gt; wenn die XplanGML im XplanManager veroeffentlicht werden soll, sonst &lt;code&gt;false&lt;/code&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadXPlanGml(file: File, planID: string, overwrite?: boolean, publish?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<XplanDokumentRest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadXPlanGml(file, planID, overwrite, publish, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XplanManagerResourceApi.uploadXPlanGml']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b> Validieren eines XPlanGML im XPlanManager.</br></br><b>Benötigte Rechte:</b> PLANWERKE_READ or EIGENE_PLANWERKE_READ or ADMIN
         * @summary Validieren eines XPlanGML im XPlanManager.
         * @param {string} planID planID des Verfahrens
         * @param {string} fileID fileID des XPlanGmlArchivs
         * @param {boolean} [skipSemantisch] Option zum Auslassen eine Validierung im XPlanValidator
         * @param {boolean} [skipGeometrisch] Option zum Auslassen eine Validierung im XPlanValidator
         * @param {boolean} [skipFlaechenschluss] Option zum Auslassen eine Validierung im XPlanValidator
         * @param {boolean} [skipGeltungsbereich] Option zum Auslassen eine Validierung im XPlanValidator
         * @param {boolean} [skipLaufrichtung] Option zum Auslassen eine Validierung im XPlanValidator
         * @param {string} [name] Optionale Angabe eines Namens fuer den zu generierenden Report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateXPlanGml(planID: string, fileID: string, skipSemantisch?: boolean, skipGeometrisch?: boolean, skipFlaechenschluss?: boolean, skipGeltungsbereich?: boolean, skipLaufrichtung?: boolean, name?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ValidationReportRest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateXPlanGml(planID, fileID, skipSemantisch, skipGeometrisch, skipFlaechenschluss, skipGeltungsbereich, skipLaufrichtung, name, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XplanManagerResourceApi.validateXPlanGml']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * XplanManagerResourceApi - factory interface
 * @export
 */
export const XplanManagerResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = XplanManagerResourceApiFp(configuration)
    return {
        /**
         * <b>Benötigte Rechte:</b> PLANWERKE_WRITE or EIGENE_PLANWERKE_WRITE or ADMIN
         * @param {string} planID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteXplanGml(planID: string, options?: any): AxiosPromise<XplanManagerStatusMessageRest> {
            return localVarFp.deleteXplanGml(planID, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Benötigte Rechte:</b> PLANWERKE_READ or EIGENE_PLANWERKE_READ or ADMIN
         * @param {string} planID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllXplanExterneUrls(planID: string, options?: any): AxiosPromise<ExterneUrlsRest> {
            return localVarFp.getAllXplanExterneUrls(planID, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Benötigte Rechte:</b> ADMIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllXplanGml(options?: any): AxiosPromise<Array<PlanInfoRest>> {
            return localVarFp.getAllXplanGml(options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b> Publizieren eines existierenden XPlanGmlArchivs im XPlan-Manager.</br></br><b>Benötigte Rechte:</b> PLANWERKE_WRITE or EIGENE_PLANWERKE_WRITE or ADMIN
         * @summary Publizieren eines existierenden XPlanGmlArchivs im XPlan-Manager.
         * @param {string} planID planID des Verfahrens
         * @param {string} fileID fileID des XPlanGmlArchivs
         * @param {boolean} [skipSemantisch] 
         * @param {boolean} [skipFlaechenschluss] 
         * @param {boolean} [skipGeltungsbereich] 
         * @param {boolean} [skipLaufrichtung] 
         * @param {boolean} [overwrite] Ggf vorhandenes XplanGML im XplanManager ueberschreiben
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishXPlanGml(planID: string, fileID: string, skipSemantisch?: boolean, skipFlaechenschluss?: boolean, skipGeltungsbereich?: boolean, skipLaufrichtung?: boolean, overwrite?: boolean, options?: any): AxiosPromise<XplanDokumentRest> {
            return localVarFp.publishXPlanGml(planID, fileID, skipSemantisch, skipFlaechenschluss, skipGeltungsbereich, skipLaufrichtung, overwrite, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b> Hochladen des XPlanGmlArchivs und optionales publizieren im XPlan-Manager.</br></br><b>Benötigte Rechte:</b> PLANWERKE_WRITE or EIGENE_PLANWERKE_WRITE or ADMIN
         * @summary Hochladen des XPlanGmlArchivs und optionales publizieren im XPlan-Manager.
         * @param {File} file 
         * @param {string} planID planID des Verfahrens
         * @param {boolean} [overwrite] Ggf vorhandenes XplanGML im XplanManager ueberschreiben
         * @param {boolean} [publish] &lt;code&gt;true&lt;/code&gt; wenn die XplanGML im XplanManager veroeffentlicht werden soll, sonst &lt;code&gt;false&lt;/code&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadXPlanGml(file: File, planID: string, overwrite?: boolean, publish?: boolean, options?: any): AxiosPromise<XplanDokumentRest> {
            return localVarFp.uploadXPlanGml(file, planID, overwrite, publish, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b> Validieren eines XPlanGML im XPlanManager.</br></br><b>Benötigte Rechte:</b> PLANWERKE_READ or EIGENE_PLANWERKE_READ or ADMIN
         * @summary Validieren eines XPlanGML im XPlanManager.
         * @param {string} planID planID des Verfahrens
         * @param {string} fileID fileID des XPlanGmlArchivs
         * @param {boolean} [skipSemantisch] Option zum Auslassen eine Validierung im XPlanValidator
         * @param {boolean} [skipGeometrisch] Option zum Auslassen eine Validierung im XPlanValidator
         * @param {boolean} [skipFlaechenschluss] Option zum Auslassen eine Validierung im XPlanValidator
         * @param {boolean} [skipGeltungsbereich] Option zum Auslassen eine Validierung im XPlanValidator
         * @param {boolean} [skipLaufrichtung] Option zum Auslassen eine Validierung im XPlanValidator
         * @param {string} [name] Optionale Angabe eines Namens fuer den zu generierenden Report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateXPlanGml(planID: string, fileID: string, skipSemantisch?: boolean, skipGeometrisch?: boolean, skipFlaechenschluss?: boolean, skipGeltungsbereich?: boolean, skipLaufrichtung?: boolean, name?: string, options?: any): AxiosPromise<ValidationReportRest> {
            return localVarFp.validateXPlanGml(planID, fileID, skipSemantisch, skipGeometrisch, skipFlaechenschluss, skipGeltungsbereich, skipLaufrichtung, name, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * XplanManagerResourceApi - object-oriented interface
 * @export
 * @class XplanManagerResourceApi
 * @extends {BaseAPI}
 */
export class XplanManagerResourceApi extends BaseAPI {
    /**
     * <b>Benötigte Rechte:</b> PLANWERKE_WRITE or EIGENE_PLANWERKE_WRITE or ADMIN
     * @param {string} planID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XplanManagerResourceApi
     */
    public deleteXplanGml(planID: string, options?: RawAxiosRequestConfig) {
        return XplanManagerResourceApiFp(this.configuration).deleteXplanGml(planID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Benötigte Rechte:</b> PLANWERKE_READ or EIGENE_PLANWERKE_READ or ADMIN
     * @param {string} planID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XplanManagerResourceApi
     */
    public getAllXplanExterneUrls(planID: string, options?: RawAxiosRequestConfig) {
        return XplanManagerResourceApiFp(this.configuration).getAllXplanExterneUrls(planID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Benötigte Rechte:</b> ADMIN
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XplanManagerResourceApi
     */
    public getAllXplanGml(options?: RawAxiosRequestConfig) {
        return XplanManagerResourceApiFp(this.configuration).getAllXplanGml(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b> Publizieren eines existierenden XPlanGmlArchivs im XPlan-Manager.</br></br><b>Benötigte Rechte:</b> PLANWERKE_WRITE or EIGENE_PLANWERKE_WRITE or ADMIN
     * @summary Publizieren eines existierenden XPlanGmlArchivs im XPlan-Manager.
     * @param {string} planID planID des Verfahrens
     * @param {string} fileID fileID des XPlanGmlArchivs
     * @param {boolean} [skipSemantisch] 
     * @param {boolean} [skipFlaechenschluss] 
     * @param {boolean} [skipGeltungsbereich] 
     * @param {boolean} [skipLaufrichtung] 
     * @param {boolean} [overwrite] Ggf vorhandenes XplanGML im XplanManager ueberschreiben
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XplanManagerResourceApi
     */
    public publishXPlanGml(planID: string, fileID: string, skipSemantisch?: boolean, skipFlaechenschluss?: boolean, skipGeltungsbereich?: boolean, skipLaufrichtung?: boolean, overwrite?: boolean, options?: RawAxiosRequestConfig) {
        return XplanManagerResourceApiFp(this.configuration).publishXPlanGml(planID, fileID, skipSemantisch, skipFlaechenschluss, skipGeltungsbereich, skipLaufrichtung, overwrite, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b> Hochladen des XPlanGmlArchivs und optionales publizieren im XPlan-Manager.</br></br><b>Benötigte Rechte:</b> PLANWERKE_WRITE or EIGENE_PLANWERKE_WRITE or ADMIN
     * @summary Hochladen des XPlanGmlArchivs und optionales publizieren im XPlan-Manager.
     * @param {File} file 
     * @param {string} planID planID des Verfahrens
     * @param {boolean} [overwrite] Ggf vorhandenes XplanGML im XplanManager ueberschreiben
     * @param {boolean} [publish] &lt;code&gt;true&lt;/code&gt; wenn die XplanGML im XplanManager veroeffentlicht werden soll, sonst &lt;code&gt;false&lt;/code&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XplanManagerResourceApi
     */
    public uploadXPlanGml(file: File, planID: string, overwrite?: boolean, publish?: boolean, options?: RawAxiosRequestConfig) {
        return XplanManagerResourceApiFp(this.configuration).uploadXPlanGml(file, planID, overwrite, publish, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b> Validieren eines XPlanGML im XPlanManager.</br></br><b>Benötigte Rechte:</b> PLANWERKE_READ or EIGENE_PLANWERKE_READ or ADMIN
     * @summary Validieren eines XPlanGML im XPlanManager.
     * @param {string} planID planID des Verfahrens
     * @param {string} fileID fileID des XPlanGmlArchivs
     * @param {boolean} [skipSemantisch] Option zum Auslassen eine Validierung im XPlanValidator
     * @param {boolean} [skipGeometrisch] Option zum Auslassen eine Validierung im XPlanValidator
     * @param {boolean} [skipFlaechenschluss] Option zum Auslassen eine Validierung im XPlanValidator
     * @param {boolean} [skipGeltungsbereich] Option zum Auslassen eine Validierung im XPlanValidator
     * @param {boolean} [skipLaufrichtung] Option zum Auslassen eine Validierung im XPlanValidator
     * @param {string} [name] Optionale Angabe eines Namens fuer den zu generierenden Report
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XplanManagerResourceApi
     */
    public validateXPlanGml(planID: string, fileID: string, skipSemantisch?: boolean, skipGeometrisch?: boolean, skipFlaechenschluss?: boolean, skipGeltungsbereich?: boolean, skipLaufrichtung?: boolean, name?: string, options?: RawAxiosRequestConfig) {
        return XplanManagerResourceApiFp(this.configuration).validateXPlanGml(planID, fileID, skipSemantisch, skipGeometrisch, skipFlaechenschluss, skipGeltungsbereich, skipLaufrichtung, name, options).then((request) => request(this.axios, this.basePath));
    }
}

